<template>
  <div>
    <div class="header">
      <van-nav-bar :title="titleType == 0
        ? $t('trade.title')
        : titleType == 1
          ? $t('futuros')
          : $t('palanca')
        " />
    </div>
    <div class="maincontent">
      <!-- 产品 -->
      <van-row class="name-icon">
        <van-image width="1.5rem" height="1.5rem" :src="require('../../assets/img/trade.png')"
          @click="open"></van-image>
        <!-- 货币行情 弹层 -->
        <van-popup v-model="isshow" position="left" :style="{ height: '100%', width: '75%' }">
          <van-row class="personage-head">
            <h3>{{ $t("trade.hbhq") }}</h3>
          </van-row>
          <van-tabs color="#f0b82d">
            <!-- 自选 -->
            <van-tab :title="$t('trade.zixuan')">
              <van-row class="popup-Increase" @click="changeproduct(item.from, item.productid, 1)"
                v-for="item in zixuanlist" :key="item.id">
                <van-col :span="4" style="display: flex;align-items: center;">
                  <van-image width="1.5rem" height="1.5rem" :src="item.logo">
                  </van-image>
                </van-col>
                <van-col :span="10">
                  <span style="text-align:left">{{ item.product_title }}</span>
                </van-col>
                <van-col :span="10" style="text-align:right">
                  <b>{{ item.current }}</b>
                </van-col>
              </van-row>
            </van-tab>
            <!-- 产品 -->
            <van-tab :title="$t('futuros')">
              <van-row class="popup-Increase" @click="changeproduct(item.from, item.pid, 1)" v-for="item in productlist"
                :key="item.id">
                <van-col :span="4" style="display: flex;align-items: center;">
                  <van-image width="1.5rem" height="1.5rem" :src="item.logo">
                  </van-image>
                </van-col>
                <van-col :span="10">
                  <span style="text-align:left">{{ item.product_title }}</span>
                </van-col>
                <van-col :span="10" style="text-align:right">
                  <b>{{ item.current }}</b>
                </van-col>
              </van-row>
            </van-tab>
            <!-- 杠杆 -->
            <van-tab :title="$t('palanca')">
              <van-row class="popup-Increase" @click="changeproduct(item.from, item.pid, 2)" v-for="item in productlist"
                :key="item.id">
                <van-col :span="4" style="display: flex;align-items: center;">
                  <van-image width="1.5rem" height="1.5rem" :src="item.logo">
                  </van-image>
                </van-col>
                <van-col :span="10">
                  <span style="text-align:left">{{ item.product_title }}</span>
                </van-col>
                <van-col :span="10" style="text-align:right">
                  <b>{{ item.current }}</b>
                </van-col>
              </van-row>
            </van-tab>
          </van-tabs>
        </van-popup>
        <!-- 弹层 -->
        <span style="margin: 0px 5px;">{{ detail.product_title }}</span>
        <van-button size="small" :style="[
        { background: pagedata.change > 0 ? '#14b95c' : '#f6185b' },
        { borderColor: pagedata.change > 0 ? '#14b95c' : '#f6185b' },
        { color: 'white' },
      ]">
          {{ pagedata.change }}%
        </van-button>
      </van-row>
      <!-- 价格 -->
      <van-row class="total">
        <van-row class="head">
          <van-col :span="12">
            <van-row class="head-now">
              <van-col :span="24">
                <h1 :style="{
        color: pagedata.change > 0 ? '#14b95c' : '#f6185b',
      }">
                  {{ pagedata.current }}
                </h1>
                <!-- <span>≈{{ pagedata.cny }} CNY</span> -->
                <!-- <span>
                  <span
                    :style="{
                      color: pagedata.change > 0 ? '#14b95c' : '#f6185b',
                    }"
                    >{{ pagedata.change }}%</span
                  >
                </span> -->
              </van-col>
            </van-row>
          </van-col>
          <van-col :span="12">
            <van-row class="head-history">
              <van-row class="head-extreme">
                <div>
                  <span>{{ $t("trade.high") }}</span>
                </div>
                <div>
                  <span>{{ $t("trade.low") }}</span>
                </div>
                <div><span>24h</span></div>
              </van-row>
              <van-row>
                <div>
                  <span>{{ tradeData.high }}</span>
                </div>
                <div>
                  <span>{{ tradeData.low }}</span>
                </div>
                <div>
                  <span>{{ tradeData.volume }}</span>
                </div>
              </van-row>
            </van-row>
          </van-col>
        </van-row>
      </van-row>
      <van-tabs class="order-List" color="#f0b82d" title-active-color="#f0b82d" @change="onChange"
        v-model="activeindex">
        <!-- K线图 -->
        <van-tab :title="$t('KLine')" name="tab1" class="GoDivV">
          <!-- k图 -->
          <div class="kView">
            <echarts :type="type" :currentprice="currentprice" />
          </div>
          <div @click="activeindex = 'tab3'" class="GoDiv">
            {{ $t("financial.list.button") }}
          </div>
        </van-tab>
        <!-- 产品简介 -->
        <van-tab :title="$t('trade.desc')" name="tab2">
          <van-row class="brief">
            <van-row class="brief-publish">
              <h3>{{ detail.product_title }}</h3>
            </van-row>
            <van-row class="brief-publish">
              <van-col :span="12" style="text-align: left;">
                <span>{{ $t("trade.time") }}</span>
              </van-col>
              <van-col :span="12" style="text-align: right;"><span>{{ detail.publishtime }}</span>
              </van-col>
            </van-row>
            <van-row class="brief-publish">
              <van-col :span="12" style="text-align: left;">
                <span>{{ $t("trade.number") }}</span>
              </van-col>
              <van-col :span="12" style="text-align: right;"><span>{{ detail.publishnum }}</span>
              </van-col>
            </van-row>
            <van-row class="brief-publish" style="border-bottom:none">
              <h3>{{ $t("trade.desc") }}</h3>
              <span v-if="lang === 'en'">
                {{ detail.en_description }}
              </span>
              <span v-if="lang === 'ru'">
                {{ detail.ru_description }}
              </span>
              <span v-if="lang === 'zh'">
                {{ detail.cn_description }}
              </span>
              <span v-if="lang === 'fr'">
                {{ detail.fr_description }}
              </span>
              <span v-if="lang === 'it'">
                {{ detail.it_description }}
              </span>
              <span v-if="lang === 'es'">
                {{ detail.es_description }}
              </span>
              <span v-if="lang === 'de'">
                {{ detail.de_description }}
              </span>
              <span v-if="lang === 'frpro'">
                {{ detail.frpro_description }}
              </span>
              <span v-if="lang === 'itpro'">
                {{ detail.itpro_description }}
              </span>
            </van-row>
          </van-row>
        </van-tab>
        <!-- 交易 -->
        <van-tab :title="$t('Trade')" name="tab3">
          <div class="operation-buyUp">
            <div v-if="titleType == 1">
              <van-row v-if="tradestep === 1">
                <van-row class="text">
                  <van-row style="height:90%">
                    <van-cell :title="$t('trade.price')" :value="currentprice" />

                    <van-row class="currency-information">
                      <div class="information" v-for="(item, index) in detail.times" :key="index">
                        <div class="informationItem" @click="choose(index)">
                          <span :class="isactive == index
        ? 'informationItemTv1To'
        : 'informationItemTv1'
        ">
                            {{ item }}S
                          </span>
                          <span class="informationItemTv2">
                            {{ detail.ratio[index] }}%
                          </span>
                        </div>
                      </div>
                    </van-row>

                    <div class="moneyV">
                      <span class="moneyTv1">{{ $t("money") }}</span>
                      <div class="moneyInput">
                        <van-field v-model="tradedata.total" :placeholder="$t('trade.input')" />
                      </div>
                      <span class="moneyTv2">{{ $t("trade.usable") }}:{{ tradedata.balance }}</span>
                    </div>
                    <div
                      style="align-items: center; display: flex; width: 100%; text-align: left; padding-left: 13px; margin: 10px 0px;">
                      <span class="moneyTv1">
                        {{ $t("trade.fangx") }}
                      </span>
                      <div class="directionV">
                        <span :class="tradedata.type == 1
        ? 'directionTv1To'
        : 'directionTv1'
        " @click="onDirectionClick(1)">{{ $t("trade.long") }}</span>
                        <span :class="tradedata.type == 2
        ? 'directionTv2To'
        : 'directionTv2'
        " @click="onDirectionClick(2)">
                          {{ $t("trade.short") }}
                        </span>
                      </div>
                    </div>
                    <div style="width: 100%; text-align: left; padding-left: 13px; margin: 10px 0px;">
                      <span v-if="detail.minimum" class="moneyTv1">
                        {{ $t("trade.min") }}:{{ detail.minimum[isactive] }}
                      </span>
                    </div>
                    <div style="width: 100%; text-align: left; padding-left: 13px; margin: 10px 0px;">
                      <span class="moneyTv1">
                        {{ $t("trade.fee") }}:{{ tradedata.fee }}
                      </span>
                    </div>
                  </van-row>
                  <van-button @click="beforsubmit">
                    {{ $t("common.confirm") }}
                  </van-button>
                </van-row>
              </van-row>
              <!-- 倒计时页面 -->
              <van-row v-else style="height:100%">
                <!-- <h3>{{ $t('trade.confirm') }}</h3> -->
                <van-row class="text">
                  <van-row style="height:90%">
                    <van-row type="flex" justify="center">
                      <van-count-down :time="tradedata.times * 1000" class="count-down" format="ss" @finish="finish" />
                    </van-row>
                    <van-cell :title="$t('trade.fangx')" :value="tradeopenType === 1
        ? $t('trade.long')
        : $t('trade.short')
        " />
                    <van-cell :title="$t('trade.touzje')" :value="tradedata.total" />
                    <van-cell :title="$t('trade.price')" :value="tradedata.price" />
                    <van-cell :title="$t('trade.yuqi')" :value="(tradedata.total * tradedata.ratio) / 100" />
                    <!-- :value="(tradedata.fee / 100) * tradedata.total" -->
                    <van-cell :title="$t('trade.fee')" :value="tradedata.fee" />
                  </van-row>
                  <van-button @click="finish">
                    {{ $t("trade.confirm") }}
                  </van-button>
                </van-row>
              </van-row>
            </div>
            <div v-else>
              <van-row>
                <van-row class="text">
                  <van-row style="height:90%">
                    <van-cell :title="$t('trade.price')" :value="currentprice" />
                    <van-row v-if="mLever && mLever.stop_profit" class="takeProfitV" style="margin-top: 10px;">
                      <span class="takeProfitTv">{{ $t("TakeProfit") }}</span>
                      <div class="takeProfitVV">
                        <span :class="mLeverPos1 == indexProfit
        ? 'takeProfitItemTo'
        : 'takeProfitItem'
        " v-for="(itemProfit,
        indexProfit) in mLever.stop_profit" @click="onItemProfit1Click(itemProfit, indexProfit)">
                          {{ itemProfit }}%
                        </span>
                      </div>
                    </van-row>
                    <van-row v-if="mLever && mLever.stop_loss" class="takeProfitV" style="margin-top: 15px;">
                      <span class="takeProfitTv">{{ $t("StopLoss") }}</span>
                      <div class="takeProfitVV">
                        <span :class="mLeverPos2 == indexProfit2
        ? 'takeProfitItemTo'
        : 'takeProfitItem'
        " v-for="(itemProfit2,
        indexProfit2) in mLever.stop_loss" @click="onItemProfit2Click(itemProfit2, indexProfit2)">
                          {{ itemProfit2 }}%
                        </span>
                      </div>
                    </van-row>
                    <van-row v-if="mLever && mLever.multiple" class="takeProfitV" style="margin-top: 15px;">
                      <span class="takeProfitTv">{{ $t("Multiple") }}</span>
                      <div class="takeProfitVV">
                        <span :class="mLeverPos3 == indexProfit3
        ? 'takeProfitItemTo'
        : 'takeProfitItem'
        " v-for="(itemProfit3, indexProfit3) in mLever.multiple"
                          @click="onItemProfit3Click(itemProfit3, indexProfit3)">
                          {{ itemProfit3 }}{{ $t("Mul") }}
                        </span>
                      </div>
                    </van-row>
                    <div
                      style="margin-top: 15px; align-items: center; display: flex; width: 100%; text-align: left; padding-left: 13px; margin: 10px 0px;">
                      <span class="moneyTv1">
                        {{ $t("trade.fangx") }}
                      </span>
                      <div class="directionV">
                        <span :class="mTradeopenLever.type == 1
        ? 'directionTv1To'
        : 'directionTv1'
        " @click="onDirectionClick(1)">{{ $t("trade.long") }}</span>
                        <span :class="mTradeopenLever.type == 2
        ? 'directionTv2To'
        : 'directionTv2'
        " @click="onDirectionClick(2)">{{ $t("trade.short") }}</span>
                      </div>
                    </div>
                    <div class="moneyV" style="margin-top: 15px;">
                      <span class="moneyTv1">{{ $t("shop.number") }}</span>
                      <div class="moneyInput">
                        <van-field v-model="mTradeopenLever.numbers" :placeholder="$t('trade.input')"
                          @input="onInputClick" />
                      </div>
                      <span class="moneyTv2">{{ $t("trade.usable") }}:{{
        mTradeopenLever.balance
      }}</span>
                    </div>

                    <!-- <div
                      style="width: 100%; text-align: left; padding-left: 13px; margin: 10px 0px;"
                    >
                      <span v-if="detail.minimum" class="moneyTv1">
                        {{ $t("trade.min") }}:{{
                          detail.minimum[this.isactive]
                        }}
                      </span>
                    </div> -->
                    <div style="width: 100%; text-align: left; padding-left: 13px; margin: 10px 0px;">
                      <span class="moneyTv1">
                        {{ $t("EstimatedIncome") }}:{{ mTradeopenLever.total }}
                      </span>
                    </div>
                    <div style="width: 100%; text-align: left; padding-left: 13px; margin: 10px 0px;">
                      <span class="moneyTv1">
                        {{ $t("trade.fee") }}:{{ mTradeopenLever.fee }}
                      </span>
                    </div>
                  </van-row>
                  <van-button @click="onSubmitClick">
                    {{ $t("common.confirm") }}
                  </van-button>
                </van-row>
              </van-row>
            </div>
          </div>
          <!-- 按钮组 -->
          <van-row class="operation">
            <van-col :span="8" style="padding-left:15px">
              <van-checkbox v-model="ischecked" icon-size="15px" checked-color="#424c66" @click="changechecked">{{
        $t("trade.addbtn") }}</van-checkbox>
            </van-col>
          </van-row>
        </van-tab>
        <!-- 委托订单 -->
        <van-tab :title="$t('trade.weit')" name="tab4">
          <van-row class="noDate" v-if="progressorder.length == 0">
            <van-empty :description="$t('common.nomore')" />
          </van-row>
          <van-row v-else>
            <div v-if="titleType == 1">
              <van-row class="currency-Increase" v-for="item in progressorder" :key="item.id"
                @click="onOrderItemClick(1, item)">
                <van-col :span="8" style="text-align:left">
                  <span>
                    <span v-if="item.type == '1'" style="color:#14b95c">
                      {{ $t("trade.more") }}
                      <van-image width="1.5rem" height="1rem" :src="require('../../assets/img/jtk.png')" />
                    </span>
                    <span v-else style="color:#f6185b">
                      {{ $t("trade.empty") }}
                      <van-image width="1.5rem" height="1rem" :src="require('../../assets/img/jtd.png')" />
                    </span>
                    <br />
                    <h5 style="color: rgb(128, 128, 128); margin-top: 10px; font-size: 16px;">
                      {{ item.product_title }}
                    </h5>
                  </span>
                </van-col>
                <van-col :span="8">
                  <span style="color: #f6185b;">
                    {{ item.profit }}
                  </span>
                </van-col>
                <van-col :span="8" class="currency-time">
                  <span>
                    <span>
                      {{ $t("trade.amount") }}<br />
                      {{ item.total_price }}
                    </span>
                  </span>
                  <br />
                  <span>
                    {{ $t("trade.jian") }} <br />
                    {{ item.open_price }}
                  </span>
                  <br />
                  <span>
                    {{ $t("trade.ping") }} <br />
                    {{ item.end_price }}
                  </span>
                  <!-- <span>
                  {{ item.createtime | dateformat }}
                </span> -->
                </van-col>
              </van-row>
            </div>
            <div v-if="titleType == 2">
              <van-row class="currency-Increase" v-for="item in progressorder" :key="item.id">
                <van-col :span="8" style="text-align:left">
                  <span>
                    <span v-if="item.type == '1'" style="color:#14b95c">
                      {{ $t("trade.more") }}
                      <van-image width="1.5rem" height="1rem" :src="require('../../assets/img/jtk.png')" />
                    </span>
                    <span v-else style="color:#f6185b">
                      {{ $t("trade.empty") }}
                      <van-image width="1.5rem" height="1rem" :src="require('../../assets/img/jtd.png')" />
                    </span>
                    <br />
                    <h5 style="color: rgb(128, 128, 128); margin-top: 10px; font-size: 16px;">
                      {{ item.product_title }}
                    </h5>
                    <span class="pointer" v-if="item.status == 0" @click="onPingClick(item)"
                      style="padding-bottom: 5px; padding-top: 5px; padding-left: 15px;padding-right: 15px; border-radius: 5px; color: #fff; margin-top: 10px; font-size: 14px; background-color: #f0b90a; font-weight: 900;">
                      {{ $t('ClosePosition') }}
                    </span>
                  </span>
                </van-col>
                <van-col :span="8">
                  <span style="color: rgb(205, 79, 100);">{{
        item.profit
      }}</span>
                </van-col>
                <van-col :span="8" class="currency-time">
                  <span>
                    <span>
                      {{ $t("trade.amount") }}<br />
                      {{ item.total_price }}
                    </span>
                  </span>
                  <br />
                  <span>
                    {{ $t("trade.jian") }} <br />
                    {{ item.open_price }}
                  </span>
                  <br />
                  <span>
                    {{ $t("trade.ping") }} <br />
                    {{ item.end_price }}
                  </span>
                </van-col>
              </van-row>
            </div>
            <van-row style="margin: 20px;">{{ $t("common.nomore") }}</van-row>
          </van-row>
        </van-tab>
        <!-- 成交订单 -->
        <van-tab :title="$t('trade.deal')" name="tab5">
          <van-row class="noDate" v-if="completedorder.length == 0">
            <van-empty :description="$t('common.nomore')" />
          </van-row>
          <van-row v-else>
            <van-row class="currency-Increase" v-for="item in completedorder" :key="item.id"
              @click="onOrderItemClick(2, item)">
              <van-col :span="8" style="text-align:left">
                <span>
                  <span v-if="item.type == '1'" style="color:#14b95c">
                    {{ $t("trade.more") }}
                    <van-image width="1.5rem" height="1rem" :src="require('../../assets/img/jtk.png')" />
                  </span>
                  <span v-else style="color:#f6185b">
                    {{ $t("trade.empty") }}
                    <van-image width="1.5rem" height="1rem" :src="require('../../assets/img/jtd.png')" />
                  </span>
                  <br />
                  <h5 style="color: rgb(128, 128, 128); margin-top: 10px; font-size: 16px;">
                    {{ item.product_name }}
                  </h5>
                </span>
              </van-col>
              <van-col :span="8">
                <span style="color: #f6185b;">{{ item.profit }}</span>
              </van-col>
              <van-col :span="8" class="currency-time">
                <span>
                  <span>
                    {{ $t("trade.amount") }}<br />
                    {{ item.total_price }}
                  </span>
                </span>
                <br />
                <span>
                  {{ $t("trade.jian") }} <br />
                  {{ item.open_price }}
                </span>
                <br />
                <span>
                  {{ $t("trade.ping") }} <br />
                  {{ item.end_price }}
                </span>
              </van-col>
            </van-row>
            <van-row style="margin: 20px;">{{ $t("common.nomore") }}</van-row>
          </van-row>
        </van-tab>
      </van-tabs>
    </div>
    <van-popup :close-on-click-overlay="false" v-model="showDialog"
      style="background-color:transparent; overflow:hidden;">
      <div class="loadingWrap">
        <van-loading v-if="showDialog" type="spinner" />
      </div>
    </van-popup>
  </div>
</template>
<script>
import echarts from "@/components/echarts.vue";
export default {
  components: { echarts },
  data() {
    return {
      activeindex: "tab3",
      titleType: 1,
      tradeData: {},
      // websorket
      websocket: {},
      // 弹出层显示
      isshow: false,
      // 弹窗的数据
      productlist: [],
      zixuanlist: [],
      // 自选
      ischecked: false,
      // 产品
      type: this.$route.params.id || "btc",
      productid: 1,
      // 页面数据
      pagedata: {},
      // 产品详情
      detail: {},
      // 当前价格
      currentprice: "",
      // 完成的订单列表
      completedorder: [],
      // 进行中的订单列表
      progressorder: [],
      // 买多买空弹出层
      tradeshow: false,
      // 确认步骤
      tradestep: 1,
      // 选择标识
      isactive: 0,
      // 买多买空数据对象
      tradedata: {
        total: '',
        type: 1, // 买多，买空
        price: 10, // 当前价格
        fee: 0, // 手续费
        balance: 0, // 可用余额
      },
      //杠杆
      mTradeopenLever: {
        total: 0, //总价
        balance: 0, //可用余额
        type: 1, // 买多，买空
        fee: 0, // 手续费
      },
      // 请求订单列表定时器
      tradetimer: "",
      lang: localStorage.getItem("lang") || "en",
      identity: "",
      mConfirmMoney: "",
      mLever: null, //杠杆数据
      mLeverPos1: 0,
      mLeverPos2: 0,
      mLeverPos3: 0,
      showDialog: false,
      tradeopenType: '',//倒计时弹窗，实际显示的交易方式
    };
  },
  created() {
    if (localStorage.getItem("index_tab")) {
      this.activeindex = localStorage.getItem("index_tab");
      localStorage.removeItem("index_tab");
    }
    if (this.$route.query.name && this.$route.query.id) {
      this.productid = this.$route.query.id;
      this.type = this.$route.query.name.toLowerCase();
    } else {
      this.productid = 1;
      this.type = "btc";
    }

    // switch (this.productid) {
    //   case "18":
    //     this.type = "ada";
    //     break;
    //   case "17":
    //     this.type = "doge";
    //     break;
    //   case "16":
    //     this.type = "bnb";
    //     break;
    //   case "15":
    //     this.type = "eos";
    //     break;
    //   case "14":
    //     this.type = "bch";
    //     break;
    //   case "13":
    //     this.type = "dot";
    //     break;
    //   case "12":
    //     this.type = "luna";
    //     break;
    //   case "11":
    //     this.type = "trx";
    //     break;
    //   case "10":
    //     this.type = "link";
    //     break;
    //   case "9":
    //     this.type = "ht";
    //     break;
    //   case "8":
    //     this.type = "neo";
    //     break;
    //   // case "7":
    //   //   this.type = "ghst";
    //   //   break;
    //   case "6":
    //     this.type = "xrp";
    //     break;
    //   case "5":
    //     this.type = "etc";
    //     break;
    //   case "3":
    //     this.type = "ltc";
    //     break;
    //   case "2":
    //     this.type = "eth";
    //     break;
    //   default:
    //     this.type = "btc";
    //     break;
    // }
    this.getinfo();
    this.getdetail();
    this.getdata();
    // 请求订单列表
    this.getlist(0);
    this.getGGlist();
    // this.getlist(1);
    this.getJYList();
    this.onChange("tab3");
    // this.tradetimer = setInterval(() => {
    //   this.getlist(0);
    //   this.getlist(1);
    // }, 5000);
  },
  beforeDestroy() {
    clearInterval(this.tradetimer);
  },
  methods: {
    async getinfo() {
      const { data } = await this.$http.get("/home/home/getindentity");
      if (data) {
        if (data.code === 200) {
          this.identity = data.data;
        }
      }
    },
    // 获取期货订单列表
    async getlist(status) {
      status = 999;
      const { data } = await this.$http.get(
        "/home/trade/orderlist/productid/" +
        this.productid +
        "/status/" +
        status
      );
      if (data) {
        if (data.code === 200) {
          if (status === 1) {
            if (data.data.length > this.completedorder.length) {
              for (
                let i = this.completedorder.length;
                i < data.data.length;
                i++
              ) {
                this.completedorder.unshift(data.data[i]);
              }
            }
          } else {
            this.progressorder = data.data;
          }
        }
      }
    },
    //获取杠杆订单
    async getGGlist() {
      const { data } = await this.$http.get(
        "/home/home/leverOrderList?page=" + 1
      );
      if (data) {
        if (data.code === 200) {
          this.progressorder = data.data.list;
        }
      }
    },
    //获取交易记录
    async getJYList() {
      const { data } = await this.$http.get("/home/trade/get_orders");
      if (data) {
        if (data.code === 200) {
          this.completedorder = data.data;
        }
      }
    },
    // 获取产品详情
    async getdetail() {
      const { data } = await this.$http.get(
        "/home/trade/getdetail/productid/" + this.productid
      );
      if (data) {
        if (data.code === 200) {

          this.gettradeto(data.data.product_code);
          this.detail = data.data;
          this.tradedata.product_title = this.detail.product_title;
          this.tradedata.total = this.detail.minimum[0];
          this.tradedata.times = this.detail.times[0];
          this.tradedata.ratio = this.detail.ratio[0];
          this.isactive = 0;
          this.tradedata.type = 1;
          this.ischecked = data.data.iszx;
        } else {
          this.$toast.error(this.$t("common.fail"));
        }
      }
    },
    // 获取币种详情
    async gettradeto(productCode) {

      const { data } = await this.$http.get(
        "/home/trade/gettradeto/type/" + productCode
      );
      if (data) {
        if (data.code === 200) {
          this.tradeData = data.data[0];
          // this.ischecked = data.data.iszx
        } else {
          this.$toast.error(this.$t("common.fail"));
        }
      }
    },
    // 获取行情最新数据
    getdata() {
      this.websocket = new WebSocket(this.wsurl + "/wsss:2052");
      this.websocket.onopen = this.socketonopen;
      this.websocket.onmessage = this.socketonmessage;
    },
    socketonopen() {
      this.websocket.send(this.type);
    },
    socketonmessage(evt) {
      this.pagedata = JSON.parse(evt.data);
      this.currentprice = this.pagedata.current;
    },
    // 打开货币行情弹出层
    async open() {
      this.isshow = true;
      // 获取自选和产品列表
      const { data } = await this.$http.get("/home/trade/getproduct");
      if (data) {
        if (data.code === 200) {
          this.productlist = data.data.productlist;
          this.zixuanlist = data.data.zixuanlist;
        }
      }
    },
    // 选择不同产品事件
    changeproduct(name, id, type) {
      if (type == 1) {
        this.titleType = 1;
      } else {
        this.titleType = 2;
      }
      this.type = name.toLowerCase();
      this.productid = id;
      this.websocket.close();
      this.getdetail();
      // this.gettradeto()
      this.getdata();
      this.isshow = false;
      this.onChange("tab3");
    },
    // 添加或取消自选事件
    async changechecked() {
      const { data } = await this.$http.get(
        "/home/trade/zixuan/productid/" +
        this.productid +
        "/istrue/" +
        this.ischecked
      );
      if (data) {
        if (data.code === 200) {
          this.$toast.success(this.$t("common.success"));
        } else {
          this.$toast.error(this.$t("common.fail"));
        }
      }
    },
    // 选择下单时间
    choose(index) {
      this.isactive = index;
      this.tradedata.times = this.detail.times[index];
      this.tradedata.ratio = this.detail.ratio[index];
    },
    async onChange(e) {
      if (e == "tab3") {
        if (this.titleType == 1) {
          this.tradeopen();
          this.getlist(0);
        } else {
          this.tradeopenLever();
          this.getGGlist();
        }
      } else if (e == "tab4") {
        if (this.titleType == 1) {
          this.getlist(0);
        } else {
          this.getGGlist();
        }
      } else if (e == "tab5") {
        // this.getlist(1);
        this.getJYList();
      }
    },
    async onDirectionClick(type) {
      this.tradedata.type = type;
      this.mTradeopenLever.type = type;
    },
    // 期货交易详情（获取余额和费率）
    async tradeopen() {
      // 判断产品下单时间并获取收费费和可用余额
      const { data } = await this.$http.get(
        "/home/trade/befororder/productid/" + this.productid
      );
      if (data) {
        if (data.code === 200) {
          this.isactive = 0;
          this.tradedata.type = 1;
          this.tradedata.fee = data.data.fee;
          this.tradedata.balance = data.data.balance;
        } else {
          this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')));
          return false;
        }
      }

      // this.tradedata.price = this.currentprice;
      this.tradedata.productid = this.productid;
      // this.tradedata.product_title = this.detail.product_title;
      this.tradeshow = true;
    },
    //获取杠杆倍数
    async tradeopenLever() {
      let dataPas = {
        p_type: this.productid,
      };
      const { data } = await this.$http.post("/home/home/getT", dataPas);
      if (data) {
        if (data.code === 200) {
          data.data.stop_profit.reverse();
          this.mLever = data.data;
          this.mLeverPos1 = 0;
          this.mLeverPos2 = 0;
          this.mLeverPos3 = 0;
          this.mTradeopenLever.p_type = this.productid;
          this.mTradeopenLever.type = 1;
          this.mTradeopenLever.numbers = 1;
          this.mTradeopenLever.fee = data.data.fee;
          this.mTradeopenLever.balance = data.data.balance;
          let money =
            (this.mLever.stop_profit[this.mLeverPos1] / 100) *
            this.mLever.multiple[this.mLeverPos3] *
            this.mTradeopenLever.numbers;
          this.mTradeopenLever.total = money;
        } else {
          this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')));
          return false;
        }
      }
    },
    //选择止盈
    onItemProfit1Click(item, index) {
      this.mLeverPos1 = index;
      let money =
        (this.mLever.stop_profit[this.mLeverPos1] / 100) *
        this.mLever.multiple[this.mLeverPos3] *
        this.mTradeopenLever.numbers;
      this.mTradeopenLever.total = money;
    },
    //选择止损
    onItemProfit2Click(item, index) {
      this.mLeverPos2 = index;
    },
    //选择倍数
    onItemProfit3Click(item, index) {
      this.mLeverPos3 = index;
      let money =
        (this.mLever.stop_profit[this.mLeverPos1] / 100) *
        this.mLever.multiple[this.mLeverPos3] *
        this.mTradeopenLever.numbers;
      this.mTradeopenLever.total = money;
    },
    //数量变化
    onInputClick(e) {
      let money =
        (this.mLever.stop_profit[this.mLeverPos1] / 100) *
        this.mLever.multiple[this.mLeverPos3] *
        this.mTradeopenLever.numbers;
      this.mTradeopenLever.total = money;
    },
    // 提交杠杆订单
    async onSubmitClick() {
      // if (this.identity.oauth < 4) {
      //   this.$toast.fail(this.$t("trade.shiming"));
      //   return false;
      // }
      if (!this.mTradeopenLever.numbers) {
        this.$toast.fail(this.$t("trade.input"));
        return false;
      }
      if (this.currentprice == 0) {
        this.$toast.fail(this.$t("trade.jian"));
        return
      }
      this.mTradeopenLever.total = this.mTradeopenLever.numbers;
      this.mTradeopenLever.price = this.currentprice;
      this.mTradeopenLever.gain = this.mLever.stop_profit[this.mLeverPos1];
      this.mTradeopenLever.loss = this.mLever.stop_loss[this.mLeverPos2];
      this.mTradeopenLever.ratio = this.mLever.multiple[this.mLeverPos3];
      this.showDialog = true;
      const { data } = await this.$http.post(
        "/home/home/leverOrder",
        this.mTradeopenLever
      );
      if (data) {
        this.showDialog = false;

        if (data.code === 200) {
          this.$toast.success(
            this.getlang(data.msg, localStorage.getItem("lang"))
          );
          this.tradeopenLever();
        } else {
          this.$toast.fail(this.$t(data.msg));
        }
      }
    },
    // 提交期货订单
    beforsubmit() {
      // if (this.identity.oauth < 4) {
      //   this.tradeshow = false;
      //   this.$toast.fail(this.$t("trade.shiming"));
      //   return false;
      // }
      if (!this.tradedata.times) {
        this.$toast.fail(this.$t("trade.selecttime"));
        return false;
      }
      // if (!this.tradedata.gudingjine) {
      //   this.$toast.fail(this.$t('trade.gudingjine'))
      //   return false
      // }
      if (!this.tradedata.total) {
        this.$toast.fail(this.$t("trade.input"));
        return false;
      }
      if (
        +this.tradedata.total < +this.detail.minimum[this.isactive] ||
        +this.tradedata.total > +this.detail.maximum[this.isactive]
      ) {
        this.$toast.fail(this.$t("trade.error"));
        return false;
      }
      if (+this.tradedata.total > +this.tradedata.balance) {
        this.$toast.fail(this.$t("trade.error1"));
        return false;
      }
      if (this.currentprice == 0) {
        this.$toast.fail(this.$t("trade.jian"));
        return
      }
      // this.tradestep = 2//取消倒计时
      this.submit();
    },
    // 倒计时结束
    finish() {
      this.tradestep = 1;
      this.tradeshow = false;
      this.shenhevisible = false;
    },
    // 提交订单
    async submit() {
      this.tradeshow = true;
      this.tradedata.price = this.currentprice;
      this.showDialog = true;
      const { data } = await this.$http.post(
        "/home/trade/order",
        this.tradedata
      );
      this.showDialog = false;
      if (data) {
        if (data.code === 200) {
          this.$toast.success(
            this.getlang(data.msg, localStorage.getItem("lang"))
          );
          this.tradeopenType = this.tradedata.type;
          this.tradestep = 2;
          this.tradeshow = true;
          this.tradeopen();
        } else {
          if (data&&data.msg=='下单金额不在规定范围内') {
            this.$toast.fail(this.$t("trade.error"));
          } else {
            this.$toast.fail(this.$t(data.msg));
          }
        }
      }
    },
    onOrderItemClick(index, item) {
      if (index == 1) {
        localStorage.setItem("index_tab", "tab4");
      } else {
        localStorage.setItem("index_tab", "tab5");
      }
      // this.$router.push({ path: "/news/OrderDeyail"});
      this.$router.push({
        path: '/news/OrderDeyail',
        query: {
          context: JSON.stringify(item)
        }
      })
    },
    //平仓
    async onPingClick(item) {
      this.showDialog = true;
      let dataPas = {
        id: item.id
      }
      const { data } = await this.$http.post(
        "/home/home/manualSettlement",
        dataPas
      );
      this.showDialog = false;
      if (data) {
        if (data.code === 200) {
          this.tradeopenLever();
          this.getGGlist();
          this.$toast.success(this.$t("common.success"));
        } else {
          this.$toast.fail(this.$t("common.error"));
        }
      }
    }
  },
};
</script>

<style lang="less" scoped>
.topTv {
  font-size: 1.1rem;
  font-size: 800;
  color: #333;
}

.header {}

.maincontent {
  padding: 44px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #fff;

  // 产品
  .name-icon {
    width: 90%;
    margin: 0.93333rem 0 0.4rem 5%;
    color: #000;
    font-size: 1.33333rem;
    text-align: left;
    display: flex;
    align-items: center;
  }

  // 价格
  .total {
    width: 100%;
    display: flex;
    justify-content: center;

    .head {
      width: 100%;
      padding: 0.8rem 1rem 0 1rem;
      color: #000;
      border-radius: 0.53333rem;
      line-height: 1.6rem;
      display: flex;

      .head-now {
        flex: 0.5;
        line-height: 2.66667rem;
        text-align: left;

        h1 {
          color: #85b480;
          font-size: 1.86667rem;
        }

        span {
          font-size: 0.85333rem;
          margin-right: 0.26667rem;
        }
      }

      .head-history {
        display: flex;
        flex: 0.5;

        div {
          text-align: left;
          flex: 0.5;
        }

        .head-extreme span {
          font-size: 0.85333rem;
          color: #b2b2b2;
          margin-right: 0.26667rem;
          display: flex;
          width: 100%;
          word-break: break-all;
        }
      }
    }
  }

  // k图
  .total .van-tabs {
    width: 95%;
    font-family: DIN-Medium, serif;
  }

  .total /deep/.van-tab--active {
    color: #f0b82d;
  }

  // 订单列表
  .order-List {
    margin-bottom: 4rem;

    // /deep/.van-tab{
    //   background-color: #666;
    //   color: #333;
    //   margin: 5px;
    // }

    // /deep/.van-tab--active{
    //   background-color: #000;
    //   color: #fff;
    //   margin: 5px;
    // }
    /deep/.van-tab__text--ellipsis {
      -webkit-line-clamp: 2;
      font-size: 1rem;
      font-family: fangsong;
      font-weight: 600;
    }

    /deep/.van-tabs__wrap {
      // border-bottom: 0.02667rem solid #e5e5e5;
    }

    .currency-Increase {
      color: #000;
      padding: 0.8rem 1.33333rem 0.8rem 1.33333rem;
      font-size: 1.6rem;
      display: flex;
      align-items: center;
      line-height: 1.6rem;
      border-bottom: 0.02667rem solid #e5e5e5;
    }

    .currency-time {
      font-size: 0.8rem;
      color: grey;
      text-align: right;
    }

    // 简介
    .brief {
      padding: 1.06667rem;
      text-align: left;

      .brief-publish {
        padding-left: 0.53333rem;
        line-height: 2.93333rem;
        border-bottom: 0.02667rem solid #e5e5e5;

        h3 {
          color: #212121;
          font-size: 1rem;
        }
      }

      span {
        font-size: 0.93333rem;
        color: grey;
      }
    }
  }

  // 操作组
  .operation {
    width: 100%;
    height: 3.2rem;
    background-color: #fff;
    // position: fixed;
    // bottom: 3.2rem;
    display: flex;
    align-items: center;
    // border-top: 0.02667rem solid #e5e5e5;
    z-index: 999;

    .van-button {
      width: 90%;
      height: 1.86667rem;
    }
  }

  // 弹出层
  .personage-head {
    height: 8%;
    background-color: #fff !important;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;

    /deep/.van-tab {
      color: grey;
      font-size: 0.9333rem;
    }

    /deep/.van-tab--active {
      color: #f0b82d;
    }

    h3 {
      font-size: 1.3333rem;
    }
  }

  .popup-Increase {
    background-color: #f1f1f1;
    color: #000;
    padding: 0.8rem 1.33333rem 0.8rem 1.33333rem;
    border-bottom: 0.02667rem solid #fff;
    font-size: 0.93333rem;
    display: flex;
    align-items: center;
  }

  // 交易弹出层
  .operation-buyUp {
    width: 100%;
    margin-top: 10px;
    // border-radius: 0.4rem;
    background-color: #fff !important;
    color: #fff !important;
    overflow: hidden;

    h3 {
      padding-top: 4%;
      padding-bottom: 4%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 0.02667rem solid #fff;
      font-weight: 400;
      color: #000;
      font-size: 1.1rem;
    }

    .text {
      height: 90%;

      .van-cell {
        font-size: 0.85333rem;
        height: 2rem;
        padding: 0.26667rem 0.8rem 0.26667rem 0.8rem;
        // background-color: #fff !important;
      }

      .van-cell:after {
        border-bottom: 0;
      }

      .van-cell__title {
        text-align: left;
      }

      .currency-information {
        line-height: 1.33333rem;
        font-size: 0.93333rem;
        display: grid;
        padding-left: 10px;
        padding-right: 10px;
        grid-template-columns: repeat(4, auto);

        .information {
          width: 100%;
          height: 3.46667rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .informationItem {
          width: 80%;
          background-color: #858a8f;
          border-radius: 0.4rem;
          display: flex;
          flex-direction: column;
        }

        .informationItemTv1 {
          color: #b9c5db;
          font-size: 15px;
          padding-top: 2px;
          border-top-left-radius: 0.4rem;
          border-top-right-radius: 0.4rem;
          background-color: #858a8f;
        }

        .informationItemTv1To {
          color: #fff;
          font-size: 15px;
          padding-top: 2px;
          border-top-left-radius: 0.4rem;
          border-top-right-radius: 0.4rem;
          background-color: #f0b82d;
        }

        .informationItemTv2 {
          color: #b9c5db;
          font-size: 15px;
          padding-bottom: 2px;
          border-bottom-left-radius: 0.4rem;
          border-bottom-right-radius: 0.4rem;
        }
      }

      /deep/.van-field__control {
        font-size: 1rem !important;
      }

      .count-down {
        margin-top: 0.53333rem;
        width: 8rem;
        height: 8rem;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4rem;
        color: #d90012;
        font-size: 2rem;
        border: 0.1667rem solid #d90012;
      }
    }

    .van-button {
      width: 90%;
      height: 10%;
      margin: 0;
      padding-top: 4%;
      padding-bottom: 4%;
      letter-spacing: 0.53333rem;
      color: #fff;
      background-color: #f0b82d;
      border: none;
      border-top: 0.02667rem solid #fff;
      font-size: 1.06667rem;
      border-radius: 5px;

      .van-button__text {
        color: #fff;
      }
    }
  }
}

.moneyV {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 13px;
  margin: 10px 0px;
}

.moneyTv1 {
  color: #000;
  font-size: 14px;
  width: 60px;
  text-align: left;
}

.moneyInput {
  width: 120px;
  background-color: #e7e6e9;
  border-radius: 10px;
  margin-left: 10px;

  .van-cell {
    background-color: #e7e6e9;
    border-radius: 5px;
  }
}

.moneyTv2 {
  color: #f0b82d;
  font-weight: 500;
  font-size: 14px;
  margin-left: 10px;
}

.directionV {
  display: flex;
  align-items: center;
  margin-left: 10px;
  border-radius: 5px;
  background-color: #edeff5;
  width: fit-content;
}

.directionTv1 {
  background-color: #edeff5;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 14px;
  color: #858a8f;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.directionTv1To {
  background-color: #f0b82d;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 14px;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.directionTv2 {
  background-color: #edeff5;
  color: #858a8f;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.directionTv2To {
  background-color: #f0b82d;
  color: #fff;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.takeProfitV {
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
}

.takeProfitTv {
  color: #000;
  font-size: 14px;
  white-space: nowrap;
  width: 60px;
  text-align: left;
}

.takeProfitVV {
  flex: 1;
  display: flex;
  white-space: nowrap;
  overflow-x: auto;
}

.takeProfitItem {
  color: #f0b82d;
  border: 0.1px solid #f5f5f5;
  background-color: #f5f5f5;
  width: 55px;
  min-width: 55px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  font-weight: 500;
}

.takeProfitItemTo {
  color: #fff;
  border: 0.1px solid #f0b82d;
  background-color: #f0b82d;
  width: 55px;
  min-width: 55px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  font-weight: 500;
}

/deep/.van-tab--active {
  color: #f0b82d !important;
}

/deep/.van-tab__text--ellipsis {
  // -webkit-line-clamp: 1 !important;
}

.GoDivV {
  display: flex;
  align-items: center;
  flex-direction: column;

}

.kView {
  width: 100%;
}

.GoDiv {
  width: 90%;
  height: 10%;
  margin: 0;
  padding-top: 4%;
  padding-bottom: 4%;
  letter-spacing: 0.53333rem;
  color: #fff;
  background-color: #f0b82d;
  border: none;
  border-top: 0.02667rem solid #fff;
  font-size: 1.06667rem;
  border-radius: 5px;
  color: #fff;
  margin-top: 10px
}
</style>
